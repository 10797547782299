import { retrieveUser } from "../me";
import { getDocumentLanguage } from "../language";

export default function provideNewsletterFormHandling(): void {
  const $form = document.getElementById("newsletterForm") as HTMLFormElement;
  const $submitButton = document.getElementById(
    "newsletterSubmitButton"
  ) as HTMLButtonElement;
  const $emailInput = document.getElementById(
    "newsletterEmailInput"
  ) as HTMLInputElement;
  const $error = document.getElementById("newsletterError");
  const $success = document.getElementById("newsletterSuccess");
  const $spinner = document.getElementById("newsletterSpinner");
  if (
    !$form ||
    !$submitButton ||
    !$emailInput ||
    !$error ||
    !$success ||
    !$spinner
  ) {
    return;
  }

  const user = retrieveUser();
  $emailInput.value = user?.email || "";
  $emailInput.addEventListener("focus", () => {
    $error.toggleAttribute("hidden", true);
    $success.toggleAttribute("hidden", true);
  });
  $form.addEventListener("submit", (event) => {
    event.preventDefault();
    $submitButton.toggleAttribute("disabled", true);
    $error.toggleAttribute("hidden", true);
    $success.toggleAttribute("hidden", true);
    $spinner.toggleAttribute("hidden", false);

    fetch("/api/subscribeToNewsletter", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: $emailInput.value,
        language: getDocumentLanguage(),
      }),
    })
      .then((response) => {
        if (response.status >= 400) {
          throw Error(`Request failed (${response.status})`);
        } else {
          // SUCCESS
          $form.reset();
          $submitButton.toggleAttribute("disabled", false);
          $success.toggleAttribute("hidden", false);
          $spinner.toggleAttribute("hidden", true);
        }
      })
      .catch(() => {
        // ERROR
        $submitButton.toggleAttribute("disabled", false);
        $error.toggleAttribute("hidden", false);
        $spinner.toggleAttribute("hidden", true);
      });
  });
  $submitButton.toggleAttribute("disabled", false);
}
