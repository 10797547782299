import { reportError } from "../helpers";

/**
 * Provide playing video after click on video cover play button (home page)
 */
export default function provideVideoCoverPlay(): void {
  const video = document.getElementById("video");
  if (!video) return;

  const videoCover = document.getElementById("video-cover");
  const videoPlayButton = document.getElementById("video-play-button");
  if (!videoCover || !videoPlayButton) return;

  videoPlayButton.addEventListener(
    "click",
    () => {
      const src = video.getAttribute("data-src");
      if (src) {
        video.setAttribute("src", src);
        video.classList.remove("hidden");
        videoCover.classList.add("hidden");
      } else {
        reportError(Error("Attribute 'data-src' missing on #video"));
      }
    },
    { passive: true }
  );
}
