import provideWelcomeMessage from "./provider/welcomeMessage";
import provideVideoCoverPlay from "./provider/videoCoverPlay";
import provideStatistics from "./provider/statistics";
import provideNewsletterFormHandling from "./provider/newsletterFormHandling";

/**
 * Initializes the home page
 */
function init(): void {
  provideVideoCoverPlay();
  provideWelcomeMessage();
  provideStatistics();
  provideNewsletterFormHandling();
}

init();
