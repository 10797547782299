import { reportError } from "../helpers";
import { getDocumentLanguage, Language } from "../language";

type Impact = "CleanOcean" | "ProtectWildlife" | "PlantTrees";
const impacts: Impact[] = ["CleanOcean", "ProtectWildlife", "PlantTrees"];

// VIEW HELPER

type Elems = { stats: HTMLElement; bg: HTMLElement; bgWrapper: HTMLElement };

type ImpactElems = Record<Impact, Elems>;

type ViewElements = { totalThankus: HTMLElement; impact: ImpactElems };

const toElems = (impact: Impact): Elems | null => {
  const stats = document.getElementById(`totalImpact${impact}`);
  const bg = document.getElementById(`bg${impact}`);
  const bgWrapper = document.getElementById(`bg${impact}Wrapper`);
  return stats && bg && bgWrapper ? { stats, bg, bgWrapper } : null;
};

const toImpactElems = (): ImpactElems | null => {
  const CleanOcean = toElems("CleanOcean");
  const ProtectWildlife = toElems("ProtectWildlife");
  const PlantTrees = toElems("PlantTrees");
  return CleanOcean && ProtectWildlife && PlantTrees
    ? { CleanOcean, ProtectWildlife, PlantTrees }
    : null;
};

const getViewElements = (): ViewElements | null => {
  const totalThankus = document.getElementById("totalThankus");
  const impact = toImpactElems();
  return totalThankus && impact ? { totalThankus, impact } : null;
};

const setCount = (elem: HTMLElement, count: number, lang: string): void => {
  const content = elem.getAttribute("data-content");
  if (content) {
    elem.innerHTML = content.replace("{{count}}", count.toLocaleString(lang));
  } else {
    reportError(Error(`Attribute 'data-content' missing on ${elem.id}`));
  }
};

const toggleVisibility = (
  elem: HTMLElement,
  on: boolean,
  classOn = "opacity-100"
): void => {
  if (on) {
    elem.classList.replace("opacity-0", classOn);
  } else {
    elem.classList.replace(classOn, "opacity-0");
  }
};

const animateScale = (elem: HTMLElement, on: boolean): void => {
  if (on) {
    setTimeout(() => {
      elem.classList.toggle("duration-6000", true);
      elem.classList.replace("scale-120", "scale-100");
    });
  } else {
    setTimeout(() => {
      elem.classList.toggle("duration-6000", false);
      elem.classList.replace("scale-100", "scale-120");
    }, 1000);
  }
};

// REMOTE DATA

type Statistics = {
  thankus: number;
  impact: Record<Impact, number>;
};

const fetchTotalStats = async (): Promise<Statistics> => {
  const res = await fetch("/api/totalStats");
  return await res.json();
};

// STATE

type State = {
  currentImpactIndex: number;
  language: Language;
  data: Statistics | null;
  dataChanged: boolean;
};

// ACTION CREATORS

type Action = (state: State) => State;

const incCurrentImpactIndex =
  () =>
  (state: State): State => ({
    ...state,
    currentImpactIndex:
      state.currentImpactIndex === impacts.length - 1
        ? 0
        : state.currentImpactIndex + 1,
  });

const setStatsData =
  (data: Statistics) =>
  (state: State): State => ({ ...state, data });

// UPDATE

const update = (state: State, actions: Action[]): State => {
  const nextState = actions.reduce(
    (prevState, action) => ({ ...prevState, ...action(state) }),
    state
  );
  return { ...nextState, dataChanged: nextState.data !== state.data };
};

// VIEW

const showImpactByIndex = (
  index: number,
  statsVisibility = false,
  el: ViewElements
): void => {
  impacts.forEach((impact, i) => {
    const elImpact = el.impact[impact];
    toggleVisibility(el.totalThankus, statsVisibility);
    toggleVisibility(elImpact.stats, statsVisibility && index === i);
    toggleVisibility(elImpact.bg, index === i, "opacity-40");
    animateScale(elImpact.bgWrapper, index === i);
  });
};

const view = (state: State, el: ViewElements): void => {
  const { data, dataChanged, currentImpactIndex, language } = state;
  showImpactByIndex(currentImpactIndex, !!data, el);
  if (data && dataChanged) {
    setCount(el.totalThankus, data.thankus, language);
    impacts.forEach((impact) => {
      setCount(el.impact[impact].stats, data.impact[impact], language);
    });
  }
};

/**
 * Provide statistics
 */
export default async function provideStatistics(): Promise<void> {
  const el = getViewElements();

  // early exit if a node is missing
  if (!el) return;

  const state: State = {
    currentImpactIndex: 0,
    language: getDocumentLanguage(),
    data: null,
    dataChanged: false,
  };

  const execute = (actions: Action[]) => {
    const nextState = update(state, actions);
    view(nextState, el);
    Object.assign(state, nextState);
  };

  const startAnimation = () => {
    setInterval(() => execute([incCurrentImpactIndex()]), 5000);
  };

  view(state, el);
  startAnimation();

  try {
    const data: Statistics = await fetchTotalStats();
    execute([setStatsData(data)]);
  } catch (error) {
    reportError(error as Error);
  }
}
