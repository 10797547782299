import { retrieveUser } from "../me";
import { reportError } from "../helpers";

/**
 * Provide welcome message on home page
 */
export default function provideWelcomeMessage(): void {
  const welcome = document.getElementById("welcome");
  if (!welcome) return;

  const user = retrieveUser();
  if (!user) return;

  const content = welcome.getAttribute("data-content");
  if (content) {
    const personalizedContent = content.replace("{{nickname}}", user.nickname);
    welcome.textContent = personalizedContent;
  } else {
    reportError(Error("Attribute 'data-content' missing on #welcome"));
  }
}
